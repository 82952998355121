<template>
  <el-dialog :visible.sync="visible" class="yt-dialog yt-dialog-default" :close-on-click-modal="false" :before-close="close" @closed="closed">
    <div slot="title" style="padding: 23px 0">
      <h4>配置岗位匹配模型</h4>
      <p>- 针对研发岗位模型下技能给不同部门配置技能要求 -</p>
    </div>
    <div class="yt-container yt-container-flex">
      <div class="yt-content-tree has-border">
        <div class="header">
          <div>
            <YTIcon style="margin: 0 5px 0 0; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>{{ organizationName }}</p>
          </div>
        </div>
        <div class="tree has-tool">
          <el-tree ref="department-tree" :data="departmentData" @node-click="handleNodeClick" node-key="departmentId" :highlight-current="true">
            <div slot-scope="{ node, data }" class="tree-node">
              <el-tooltip effect="dark" :content="data.name" :disabled="data.name.length < 10" placement="top-start">
                <div class="el-tree-node__label">{{ data.name }}</div>
              </el-tooltip>
              <div v-if="data.configured" class="tools">
                <p class="has-configured">已配置</p>
              </div>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="yt-content has-border">
        <div class="yt-header-toolbar">
          <div>
            <span style="margin-right: 12px; font-weight: bold">岗位要求配置</span>
            <el-select
              v-model="activeTemplate"
              @change="getModelDetail"
              placeholder="选择模板"
              class="yt-search-select"
              style="margin-right: 6px"
              clearable
            >
              <el-option v-for="(item, index) in modelList" :key="item.modelId" :label="item.modelName" :value="item.modelId"> </el-option>
            </el-select>
            <el-tooltip effect="dark" content="可以从已有模板中选择岗位要求配置，并可以保存为模板" placement="top">
              <YTIcon :href="'#icon-tishi'"></YTIcon>
            </el-tooltip>
          </div>
          <el-button type="primary" size="small" @click="saveModel">保存为模板</el-button>
        </div>
        <div class="match-table">
          <ul class="thead row">
            <li class="item first">
              评级名称
              <el-tooltip effect="dark" content="给岗位模型下的技能设置不同的评级，评级最长8个字" placement="top">
                <YTIcon :href="'#icon-tishi'"></YTIcon>
              </el-tooltip>
            </li>
            <li class="item second">要求比例</li>
            <li>操作</li>
          </ul>
          <div v-if="rateList.length < 10" class="add-button" @click="addRate">
            <i class="el-icon-circle-plus-outline" style="margin-right: 12px; font-size: 16px" />
            添加一行评级
          </div>
          <template v-for="(item, index) in rateList">
            <ul :key="index" class="tbody row">
              <li class="item first">
                <el-autocomplete
                  class="inline-input"
                  :class="{ 'has-error': rateNameError === index }"
                  v-model="item.rateName"
                  :fetch-suggestions="querySearch"
                  suffix-icon="el-icon-arrow-down"
                  style="width: 100%"
                  placeholder="请输入或选择评级名称"
                  :maxlength="8"
                ></el-autocomplete>
              </li>
              <li class="item second">
                <p style="width: 17px;">{{ item.lowScore }}</p>
                <p style="margin: 0 6px">-</p>
                <template v-if="index > 0 && index === rateList.length - 1">
                  <span>100</span>
                </template>
                <template v-else>
                  <el-autocomplete
                    class="inline-input"
                    :class="{ 'has-error': item.isScoreError }"
                    v-model="item.highScore"
                    :fetch-suggestions="queryHighScoreSearch"
                    oninput="value = value.replace(/[^0-9]/g, '')"
                    suffix-icon="el-icon-arrow-down"
                    style="width: 260px"
                    placeholder="请输入内容"
                    @change="computeScore($event, index)"
                    @select="selectScore($event, index)"
                  ></el-autocomplete>
                </template>
                <p style="margin-left: 6px">%</p>
              </li>
              <li v-if="index > 0" class="item third" @click="deleteRate(index)">删除</li>
            </ul>
          </template>
        </div>
        <div style="border-bottom: 1px solid #F0F0F0"></div>
        <h3 style="margin: 30px 0 12px 20px; font-size: 16px">技能要求配置</h3>
        <div class="match-table">
          <ul class="thead row">
            <li class="item first">技能列表</li>
            <li class="item second">技能要求</li>
          </ul>
          <template v-for="(item, index) in skillList">
            <ul :key="index" class="tbody row">
              <li class="item first">{{ item.mainSkillName }}{{ item.subSkillName !== null ? `-${item.subSkillName}` : '' }}</li>
              <li class="item second">
                <el-select v-model="item.config" style="width: 320px" placeholder="请选择技能要求">
                  <template v-for="rate in rateList">
                    <el-option v-if="rate.rateName !== ''" :key="rate.id" :label="rate.rateName" :value-key="rate.id" :value="rate.id">
                      <span>{{ rate.rateName }}</span>
                    </el-option>
                  </template>
                </el-select>
                <div v-if="item.config !== ''" class="rate">{{ itemConfigRate(item.config) }}</div>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </div>
    <div class="footer" slot="footer">
      <el-button @click="reset" class="button">重置</el-button>
      <el-button type="primary" class="button" :loading="loading" @click="submit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import departmentApi from '@api/department'
import postDepartmentMatchApi from '@api/postDepartmentMatch'
export default {
  name: 'PostMatchConfigDialog',
  components: { YTIcon },
  data() {
    return {
      visible: false,
      mode: true, //是否新建
      activeData: {}, //岗位部门信息
      organizationName: '', //根部门
      departmentData: [], //部门
      departmentId: {}, //选中部门id
      modelList: [], //模板
      activeTemplate: '', //当前模板
      rateList: [{ id: 0, rateName: '', lowScore: 0, highScore: '100', isScoreError: false }], //评级列表
      rateEnum: [{ value: '低' }, { value: '中' }, { value: '高' }, { value: '一般要求' }, { value: '中要求' }, { value: '强要求' }],
      highScoreEnum: [{ value: '5' }],
      rateNameError: '', //要求名称错误
      skillList: [], //技能列表
      loading: false
    }
  },
  computed: {
    itemConfigRate() {
      return id => {
        let rate = this.rateList.filter(item => {
          return item.id === id
        })[0]
        if (rate) {
          return `${rate.lowScore}-${rate.highScore}%`
        } else {
          return ''
        }
      }
    }
  },
  created() {
    let val = 10
    while (val < 100) {
      this.highScoreEnum.push({ value: val.toString() })
      val += 5
    }
  },
  methods: {
    //data
    getDepartment() {
      //部门树
      departmentApi.getPostMatchDepartmentsTree(this.activeData.postId).then(res => {
        this.departmentData = res.res.children
        this.organizationName = res.res.name
        if (this.mode) {
          this.departmentId = this.departmentData[0].departmentId
        } else {
          this.departmentId = this.activeData.departmentId
        }
        this.setCurrentKey(this.departmentId)
        this.getConfig()
      })
    },
    getConfig() {
      let payload = {
        departmentId: this.departmentId,
        postId: this.activeData.postId
      }
      postDepartmentMatchApi.getDetailByDepartmentId(payload).then(res => {
        if (res.code === 0) {
          if (res.res.hasOwnProperty('rateVOList') && res.res.rateVOList.length > 0) {
            this.rateList = res.res.rateVOList.map(item => {
              item.highScore = item.highScore.toString()
              return item
            })
          } else {
            this.rateList = [{ id: 0, rateName: '', lowScore: 0, highScore: '100', isScoreError: false }]
          }
          this.skillList = res.res.skillConfigVOS.map(item => {
            if (item.level === null) {
              item.config = ''
            } else {
              item.config = this.rateList[item.level].id
            }
            return item
          })
        }
      })
    },
    getModelList() {
      postDepartmentMatchApi.getModelList().then(res => {
        this.modelList = res.res
      })
    },
    getModelDetail(value) {
      if (value === '') {
        this.rateList = [{ id: 0, rateName: '', lowScore: 0, highScore: '100', isScoreError: false }]
        this.checkRateExist()
      } else {
        postDepartmentMatchApi.getModelDetail(value).then(res => {
          this.rateList = res.res.map(item => {
            item.highScore = item.highScore.toString()
            return item
          })
          this.checkRateExist()
        })
      }
    },
    // events
    open(data, mode) {
      //打开弹窗
      this.visible = true
      this.mode = mode
      this.activeData = data
      this.getDepartment()
      this.getModelList()
    },
    handleNodeClick(data) {
      //树节点点击
      this.departmentId = data.departmentId
      this.getConfig()
      this.activeTemplate = ''
    },
    setCurrentKey(departmentId) {
      //设置树高亮
      this.$nextTick(() => {
        this.$refs['department-tree'].setCurrentKey(departmentId ? departmentId : '')
      })
    },
    saveModel() {
      //保存为模板
      let valid = this.validateForm()
      if (valid) {
        let addRateForms = this.rateList.map((item, index) => {
          return {
            ...item,
            level: index
          }
        })
        postDepartmentMatchApi.saveModel({ postId: this.activeData.postId, addRateForms: addRateForms }).then(res => {
          if (res.res === true) {
            this.$message.success('保存模板成功')
            this.getModelList()
          }
        })
      }
    },
    addRate() {
      //添加一行要求
      if (this.rateList.length === 1) {
        this.$message.warning('请先修改第一行的数值')
        return
      }
      this.rateList.splice(this.rateList.length - 1, 0, {
        id: this.$generateUUID(),
        rateName: this.rateList[this.rateList.length - 1].rateName,
        lowScore: this.rateList[this.rateList.length - 2].highScore,
        highScore: undefined,
        isScoreError: false
      })
      this.$set(this.rateList[this.rateList.length - 1], 'lowScore', '')
      this.$set(this.rateList[this.rateList.length - 1], 'rateName', '')
    },
    computeScore(text, index) {
      //输入评分后检验
      if (parseInt(text) <= 0) {
        //不可以小于0,第一行重置成100,大于两行时重置成下一行的末尾
        this.$message.warning('请输入大于0的数值')
        this.$set(this.rateList[index], 'isScoreError', false)
        if (index === 0 && this.rateList.length === 1) {
          this.$set(this.rateList[index], 'highScore', '100')
        } else {
          this.$set(this.rateList[index], 'highScore', this.rateList[index + 1].lowScore)
        }
        return
      } else if (parseInt(text) > 100) {
        this.$message.warning('请输入不大于100的数值')
        this.$set(this.rateList[index], 'isScoreError', false)
        this.$set(this.rateList[index], 'highScore', '100')
        return
      } else if (index === 0 && this.rateList.length === 1) {
        this.rateList.push({
          id: 1,
          rateName: '',
          lowScore: text,
          highScore: '100',
          isScoreError: false
        })
      }
      if (parseInt(text) >= parseInt(this.rateList[index + 1].highScore)) {
        this.$message.warning(`请输入不大于${this.rateList[index + 1].highScore}的数值`)
        this.$set(this.rateList[index], 'isScoreError', true)
        return
      }
      if (index !== 0 && parseInt(text) <= parseInt(this.rateList[index - 1].highScore)) {
        this.$message.warning(`请输入不小于${this.rateList[index - 1].highScore}的数值`)
        this.$set(this.rateList[index], 'isScoreError', true)
        return
      }
      if (parseInt(text) < 100) {
        this.$set(this.rateList[index], 'isScoreError', false)
        this.$set(this.rateList[index + 1], 'lowScore', text)
      }
    },
    selectScore(data, index) {
      //比例选中
      this.$set(this.rateList[index], 'highScore', data.value)
      this.$set(this.rateList[index], 'isScoreError', false)
      this.computeScore(data.value, index)
    },
    deleteRate(index) {
      //删除要求
      this.rateList.splice(index, 1)
      if (this.rateList.length === 1) {
        this.$set(this.rateList[0], 'highScore', '100')
      } else {
        if (index === this.rateList.length) {
          this.$set(this.rateList[index - 1], 'highScore', '100')
        } else if (index < this.rateList.length - 2) {
          this.$set(this.rateList[index], 'highScore', this.rateList[index + 1].lowScore)
        } else {
          this.$set(this.rateList[index - 1], 'highScore', this.rateList[index].lowScore)
        }
      }
      this.checkRateExist()
    },
    submit() {
      let valid = this.validateForm()
      if (valid) {
        let payload = {
          addPostSkillRateForms: this.skillList.map(item => {
            let level = this.rateList.findIndex(rate => {
              return rate.id === item.config
            })
            return {
              level: level !== -1 ? level : null,
              postSkillId: item.postSkillId
            }
          }),
          addRateForms: this.rateList.map((item, index) => {
            item.level = index
            return item
          }),
          departmentId: this.departmentId,
          postId: this.activeData.postId
        }
        this.loading = true
        if (this.mode) {
          this.create(payload)
        } else {
          this.update(payload)
        }
      }
    },
    create(payload) {
      postDepartmentMatchApi
        .saveMatch(payload)
        .then(res => {
          if (res.res === true) {
            this.$message.success('部门配置成功')
            this.visible = false
            this.$emit('refresh')
          } else {
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    update(payload) {
      postDepartmentMatchApi
        .updateMatch(payload)
        .then(res => {
          if (res.res === true) {
            this.$message.success('部门配置成功')
            this.visible = false
            this.$emit('refresh')
          } else {
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    reset() {
      //重置技能列表要求配置
      this.skillList = this.skillList.map(item => {
        item.config = ''
        return item
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
      this.activeTemplate = ''
      this.departmentId = ''
      this.rateNameError = ''
      this.skillList = []
      this.rateList = []
    },
    //utils
    querySearch(queryString, cb) {
      let rates = this.rateEnum
      let results = queryString
        ? rates.filter(item => {
            return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          })
        : rates
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    queryHighScoreSearch(queryString, cb) {
      let rates = this.highScoreEnum
      let results = queryString
        ? rates.filter(item => {
            return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          })
        : rates
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    validateForm() {
      let reg = /^(?=.*\S).+$/
      //验证评级名称
      let rateNameError = this.rateList.findIndex(item => {
        return item.rateName === '' || !reg.test(item.rateName)
      })
      if (rateNameError > -1) {
        this.$message.error('评级名称不能为空或全为空格')
        this.rateNameError = rateNameError
        return false
      } else {
        this.rateNameError = ''
      }
      //验证比例
      let rateScoreError = this.rateList.findIndex(item => {
        return item.highScore === ''
      })
      if (rateScoreError > -1) {
        this.$message.error('评级比例不能为空')
        this.$set(this.rateList[rateScoreError], 'isScoreError', true)
        return false
      }
      return true
    },
    checkRateExist() {
      this.skillList = this.skillList.map(skill => {
        //查询要求是否被删除
        let isExist = this.rateList.some(rate => {
          return rate.id === skill.config
        })
        //被删除时清空选择
        if (!isExist) {
          skill.config = ''
        }
        return skill
      })
    }
  }
}
</script>

<style lang="less" scoped>
.yt-dialog {
  ::v-deep .el-dialog {
    margin: 50px auto !important;
    width: 1080px;
    .el-dialog__header {
      height: auto;
      border-bottom: none;
      line-height: 1;
      h4 {
        font-size: @default;
        margin-bottom: 19px;
      }
      p {
        font-size: @medium;
        color: #999999;
      }
    }
    .el-dialog__body {
      height: calc(100vh - 292px);
      max-height: unset;
      padding: 0 20px;
      color: #000000;
    }
    .el-dialog__footer {
      border-top: none;
    }
  }
}
.yt-container {
  height: 100%;
  padding: 0;
  background-color: #fff;
  .has-border {
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    box-shadow: none;
  }
  .yt-content-tree {
    width: 260px;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    box-shadow: none;
    .header {
      background-color: #f8f8f8;
    }
    .tree .tools {
      display: block;
    }
    .is-current {
      .tools {
        background-color: #f0f0f0;
      }
    }
    .has-configured {
      width: 48px;
      height: 20px;
      line-height: 20px;
      color: #4ce47a;
      background: #effff4;
      border-radius: 4px;
      text-align: center;
      font-size: @small;
    }
  }
  .yt-content {
    padding: 0;
    overflow-y: auto;
    .yt-header-toolbar {
      margin: 12px 20px;
      justify-content: space-between;
    }
    svg {
      font-size: @default;
      fill: #999999;
    }
  }
}
.match-table {
  margin: 0 20px;
  padding-bottom: 6px;
  .thead {
    background: #f0f0f0;
    font-weight: bold;
  }
  .row {
    .flexStyle(flex, flex-start);
    height: 50px;
    .item {
      &.first {
        padding: 0 20px;
        width: 320px;
      }
      &.second {
        .flexStyle(flex, flex-start);
        width: 338px;
        position: relative;
        .rate {
          position: absolute;
          left: 150px;
          color: #448bff;
        }
      }
      &.third {
        color: #448bff;
        cursor: pointer;
      }
      ::v-deep .el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }
  }
  .tbody {
    padding: 12px 0;
    height: 62px;
    border-bottom: 1px solid #f0f0f0;
    &:last-of-type {
      border-bottom: none;
    }
    .item {
    }
  }
  .add-button {
    .flexStyle();
    width: calc(100% - 40px);
    height: 38px;
    line-height: 36px;
    margin: 12px 20px 8px;
    background: #ffffff;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    color: #999999;
    cursor: pointer;
  }
  .has-error {
    ::v-deep .el-input__inner {
      border-color: #ff5050;
    }
  }
}
</style>
